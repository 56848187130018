<script>
	// Props to accept the filename and JSON content
	export let filename = 'schema.json';
	export let content = '';
</script>

<div class="w-full md:w-1/2 px-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
	<div class="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
		<!-- Editor Header -->
		<div class="bg-gray-900 text-white px-4 py-2 flex items-center justify-between">
			<span class="text-sm font-mono">{filename}</span>
			<div class="flex space-x-2">
				<!-- Fake buttons for the editor -->
				<span class="w-3 h-3 bg-red-500 rounded-full"></span>
				<span class="w-3 h-3 bg-yellow-500 rounded-full"></span>
				<span class="w-3 h-3 bg-green-500 rounded-full"></span>
			</div>
		</div>
		<!-- Editor Content (JSON Schema) -->
		<div class="p-4 bg-gray-800 text-green-400 font-mono text-sm h-96 overflow-auto text-left">
			<pre>{content}</pre>
		</div>
	</div>
</div>
