<script>
	import JsonEditor from '$lib/components/JsonEditor.svelte';
	import WaitlistForm from '$lib/components/WaitlistForm.svelte';
	import AOS from 'aos';
	import { onMount, tick } from 'svelte';
	import { CircleXmarkRegular } from 'svelte-awesome-icons';

	onMount(() => {
		AOS.init();
	});

	let waitlistSection;

	// Example JSON content to pass into the editor
	let jsonContent = `{
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "properties": {
        "total_products_results": {
            "type": "integer",
            "description": "The total number of products returned in the search results. Example: 250",
        },
        "country": {
            "type": "string",
            "description": "The full name of the country where the product is listed. Use standard country names (e.g., 'United Kingdom', 'United States'). Example: 'United Kingdom'",
        },
        "domain": {
            "type": "string",
            "description": "The domain from which the product data was retrieved, usually in URL format. Example: 'amazon.co.uk'",
        },
        "products": {
            "type": "array",
            "description": "An array of product objects, each containing details about an individual product listed in the search results.",
            "items": {
                "type": "object",
                "properties": {
                    "asin": {
                        "type": "string",
                        "description": "Amazon Standard Identification Number (ASIN), a unique identifier for the product. Example: 'B08N5WRWNW'",
                    },
                    "product_title": {
                        "type": "string",
                        "description": "The title or name of the product as listed on the website. Example: 'Echo Dot (4th Generation) Smart Speaker with Alexa'",
                    },
                    "product_price": {
                        "type": "number",
                        "description": "The price of the product as a numeric value. Exclude currency symbols. Example: 49.99",
                    },
                    "currency": {
                        "type": "string",
                        "description": "The currency in which the product price is listed, represented by a three-letter ISO 4217 code. Example: 'GBP'",
                    },
                },
                "required": ["asin", "product_title"],
                "description": "Details for an individual product, including its identifier, name, price, and currency.",
            },
        },
    },
    "required": ["total_products_results", "country", "domain", "products"],
}`;

	// Function to scroll to the waitlist section
	function scrollToWaitlist() {
		waitlistSection.scrollIntoView({ behavior: 'smooth' });
	}

	let showModal = false;
	let openButton; // Reference to the button for returning focus
	let modalContent; // Reference to the modal content for focus control

	function openModal() {
		showModal = true;
		tick().then(() => modalContent.focus()); // Set focus to modal after it opens
	}

	function closeModal() {
		showModal = false;
		openButton.focus(); // Return focus to the button after closing modal
	}
</script>

<!-- Hero Section -->
<section class="py-24 bg-gradient-to-r from-primary-600 via-purple-500 to-primary-700 text-white">
	<div class="container mx-auto px-6 md:px-12 flex flex-col md:flex-row items-center md:gap-12">
		<!-- Text Content -->
		<div class="md:w-1/2" data-aos="fade-right">
			<h1 class="text-5xl md:text-6xl font-heading font-bold mb-4 leading-tight">
				AI-Powered Web Data Extraction
			</h1>
			<h2 class="text-2xl md:text-3xl font-heading font-semibold mb-6">
				Effortless, Scalable, and Code-Free
			</h2>
			<p class="text-lg md:text-xl mb-8 font-sans md:pr-8">
				Extract web data using natural language and JSON schemas – no coding required.
			</p>
			<button
				class="inline-flex items-center bg-accent-500 text-gray-900 font-bold py-4 px-8 rounded-full hover:bg-accent-600 hover:text-white transition transform hover:-translate-y-1"
				on:click={scrollToWaitlist}
			>
				Join the Waitlist Now
			</button>
		</div>

		<!-- Image Content -->
		<div class="md:w-1/2 mt-12 md:mt-0" data-aos="fade-left">
			<!-- Use a button to ensure accessibility -->
			<button
				class="video-button"
				on:click={openModal}
				aria-label="Open video"
				bind:this={openButton}
			>
				<img
					src="https://ik.imagekit.io/wrfy4pvbj/datadonkee/product_demo_screenshot.png?f=webp"
					alt="Product demo video thumbnail showcasing Data Donkee's web data extraction capabilities."
					width="800"
					height="450"
				/>
			</button>
		</div>

		{#if showModal}
			<!-- DaisyUI modal structure -->
			<div
				class="modal modal-open"
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				<div class="modal-box w-full max-w-4xl h-auto relative p-8" bind:this={modalContent}>
					<!-- Close button in DaisyUI style -->
					<button
						class="btn btn-sm btn-circle absolute right-4 top-4 bg-white hover:bg-gray-100 z-10"
						on:click={closeModal}
						aria-label="Close video"><CircleXmarkRegular /></button
					>

					<!-- Video element -->
					<video
						class="w-full h-full rounded-lg shadow-2xl"
						loop
						controls
						poster="https://ik.imagekit.io/wrfy4pvbj/datadonkee/product_demo_screenshot.png?f=webp"
					>
						<source
							src="https://ik.imagekit.io/wrfy4pvbj/datadonkee/product_demo.webm/ik-video.mp4"
							type="video/webm"
						/>
						<source
							src="https://ik.imagekit.io/wrfy4pvbj/datadonkee/product_demo.webm/ik-video.mp4"
							type="video/mp4"
						/>
						Your browser does not support the video tag.
						<track kind="captions" label="English captions" src="/captions.vtt" srclang="en" />
					</video>
				</div>
			</div>
		{/if}
	</div>
</section>

<!-- Problem Section -->
<section class="py-16 px-4 bg-light">
	<h2 class="text-3xl font-heading font-bold text-center mb-8 text-dark" data-aos="fade-up">
		Tired of Complex Web Scraping?
	</h2>
	<ul
		class="max-w-2xl mx-auto space-y-4 text-lg text-gray-700 text-center"
		data-aos="fade-up"
		data-aos-delay="200"
	>
		<li>⏰ Spending countless hours coding and maintaining scrapers?</li>
		<li>🔧 Frustrated by websites constantly changing and breaking your code?</li>
		<li>🤖 Current tools fall short on complex, dynamic sites and large datasets?</li>
		<li>💰 High costs for scaling web extraction tools, breaking your budget?</li>
	</ul>
	<p
		class="text-center mt-8 text-xl font-sans text-secondary-700"
		data-aos="fade-up"
		data-aos-delay="400"
	>
		<strong class="text-2xl"
			>Streamline your data extraction with Data Donkee's AI-powered Web Agent.</strong
		>
	</p>
</section>

<!-- Meet Data Donkee Section with JSON Schema Editor -->
<section class="py-16 bg-white">
	<div
		class="container mx-auto flex flex-col md:flex-row items-center px-4 md:px-6 lg:px-12 md:gap-12"
	>
		<!-- Text Content -->
		<div class="md:w-1/2 mb-8 md:mb-0" data-aos="fade-right">
			<h2 class="text-3xl font-heading font-bold mb-6 text-dark">Meet Data Donkee</h2>
			<p class="text-lg text-gray-700 mb-4">
				Break free from coding barriers with our AI Web Agent. Describe your data needs in plain
				language and get structured data output.
			</p>
			<ul class="space-y-4 text-lg text-gray-700">
				<li>
					🚫 <strong>No Coding Needed:</strong> Specify your data requirements easily using natural language.
				</li>
				<li>
					📋 <strong>JSON Schema Support:</strong> Define your output structure to get exactly what you
					need.
				</li>
				<li>
					🎯 <strong>Consistent and Reliable:</strong> Our agent ensures accurate extractions without
					hallucinations.
				</li>
				<li>
					📈 <strong>Scalable and Cost-Effective:</strong> Scale seamlessly across sites at a lower cost
					than other AI based solutions.
				</li>
			</ul>
		</div>

		<!-- JSON Schema Editor Component -->
		<JsonEditor filename="ProductListingSchema.json" content={jsonContent} />
	</div>
</section>

<!-- How It Works Section -->
<section class="py-16 bg-light">
	<h2 class="text-3xl font-heading font-bold text-center mb-12 text-dark" data-aos="fade-up">
		How It Works
	</h2>
	<div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
		<div class="text-center" data-aos="zoom-in" data-aos-delay="100">
			<div class="text-6xl text-secondary-500 mb-4">1️⃣</div>
			<h3 class="text-xl font-heading font-bold mb-2 text-gray-800">Describe Your Data</h3>
			<p class="text-gray-700">
				Use natural language and JSON schema definition to tell our agent what you need.
			</p>
		</div>
		<div class="text-center" data-aos="zoom-in" data-aos-delay="200">
			<div class="text-6xl text-secondary-500 mb-4">2️⃣</div>
			<h3 class="text-xl font-heading font-bold mb-2 text-gray-800">
				Our AI Builds the Extraction
			</h3>
			<p class="text-gray-700">We create a custom scraper based on your JSON schema.</p>
		</div>
		<div class="text-center" data-aos="zoom-in" data-aos-delay="300">
			<div class="text-6xl text-secondary-500 mb-4">3️⃣</div>
			<h3 class="text-xl font-heading font-bold mb-2 text-gray-800">Collect and Download</h3>
			<p class="text-gray-700">Receive clean, structured data ready for analysis.</p>
		</div>
	</div>
</section>

<!-- Frequently Asked Questions -->
<section class="py-16 px-4 bg-light">
	<h2 class="text-3xl font-heading font-bold text-center mb-8 text-dark" data-aos="fade-up">
		Frequently Asked Questions
	</h2>
	<div class="max-w-3xl mx-auto space-y-6 text-center">
		<div data-aos="fade-up" data-aos-delay="100">
			<h3 class="text-xl font-heading font-semibold text-gray-800">
				Can I use Data Donkee without any coding experience?
			</h3>
			<p class="text-gray-700">
				Yes! Our AI Web Agent allows you to specify data extraction needs in plain language, with no
				coding required.
			</p>
		</div>
		<div data-aos="fade-up" data-aos-delay="200">
			<h3 class="text-xl font-heading font-semibold text-gray-800">
				How does Data Donkee handle complex site structures?
			</h3>
			<p class="text-gray-700">
				Our agent autonomously navigates and extracts data from complex structures, maintaining
				accuracy and consistency.
			</p>
		</div>
		<div data-aos="fade-up" data-aos-delay="300">
			<h3 class="text-xl font-heading font-semibold text-gray-800">
				What makes Data Donkee cost-effective for large-scale use?
			</h3>
			<p class="text-gray-700">
				By generating reusable code and minimizing additional processing costs, Data Donkee provides
				a scalable solution without breaking your budget.
			</p>
		</div>
	</div>
</section>

<!-- Join Waitlist Section -->
<section class="py-16 px-4 bg-white" bind:this={waitlistSection}>
	<div class="container mx-auto flex flex-col items-center justify-center">
		<h2
			class="text-3xl font-heading font-bold text-center mb-8 text-primary-600"
			data-aos="fade-up"
		>
			Join Our Waitlist
		</h2>
		<p
			class="text-center text-gray-700 leading-relaxed mb-8"
			data-aos="fade-up"
			data-aos-duration="1000"
			data-aos-delay="300"
		>
			Sign up to be the first to know about our web data extraction solutions and get early access.
		</p>

		<!-- Waitlist Form -->
		<WaitlistForm />
	</div>
</section>

<style>
	.video-button img {
		width: 100%;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}
</style>
