<script>
	import { onMount } from 'svelte';

	onMount(() => {
		// Load the stylesheet
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = 'https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css';
		document.head.appendChild(link);

		// Load the script
		const script = document.createElement('script');
		script.src = 'https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js';
		document.body.appendChild(script);
	});
</script>

<div
	id="getWaitlistContainer"
	class="w-full max-w-md"
	data-waitlist_id="20441"
	data-widget_type="WIDGET_1"
	data-aos="zoom-in"
	data-aos-duration="1000"
	data-aos-delay="200"
></div>
